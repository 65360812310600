import React from 'react';
import { NextPage } from 'next';
import { blogCmsClient } from 'src/lib/contentful-client';
import { ContentfulTemplates, BLOG_ENTRIES_PER_PAGE } from 'src/lib/constants';
import { BlogOverviewTemplate } from 'src/templates/BlogOverviewTemplate';
import { optimizeContentfulImages } from 'src/lib/image-helpers';

import { Entry, Sys } from 'contentful';

interface BannerImage {
  fields: {
    description: string;
    file: {
      contentType: string;
      details: {
        image: {
          width: number;
          height: number;
        };
        size: number;
      };
      url: string;
    };
    title: string;
  };
  sys: Sys;
}

export interface Component {
  fields: {
    body?: {
      content: {
        content: {
          content: {
            data: unknown;
            marks: { type: string }[];
            nodeType: string;
            value: string;
          }[];
          data: {
            uri?: string;
          };
          marks?: { type: string }[];
          nodeType: string;
          value?: string;
        }[];
        data: unknown;
        nodeType: string;
      }[];
      data: unknown;
      nodeType: string;
    };
    internalTitle: string;
  };
  sys: Sys;
}

export interface Tag {
  fields: {
    tag: string;
  };
  sys: Sys;
}

export interface PostMetaFields {
  bannerImage?: BannerImage;
  components?: Component[];
  excerpt?: string;
  heading?: string;
  metaDescription?: string;
  metaTitle?: string;
  pageSlug?: string;
  published?: string;
  tags?: Tag[];
}

interface BlogPost {
  fields?: PostMetaFields;
  sys?: Sys;
}

interface Fields {
  heading: string;
  metaDescription: string;
  metaTitle: string;
}

interface IndexProps {
  fields?: Fields;
  postsCount?: number;
  pageCount?: number;
  posts?: Entry<BlogPost>[];
  preview: boolean;
}

interface StaticProps {
  props: IndexProps;
}

const BlogIndex: NextPage<IndexProps> = ({
  fields,
  posts,
  postsCount,
  pageCount,
}) => (
  <BlogOverviewTemplate
    fields={fields}
    posts={posts}
    heading={fields.heading}
    pageNum={1}
    totalPages={pageCount}
    hasFeaturedPost={true}
    basePath='/blog'
  />
);
export async function getStaticProps({ preview }): Promise<StaticProps> {
  const overviewPromise = blogCmsClient.getEntries({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    content_type: ContentfulTemplates.BLOG_OVERVIEW,
  });
  const postPromise = blogCmsClient.getEntries({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    content_type: ContentfulTemplates.BLOG_POST,
    skip: 0,
    limit: BLOG_ENTRIES_PER_PAGE,
    order: '-fields.published',
  });
  let overview, entries;
  try {
    [overview, entries] = await Promise.all([overviewPromise, postPromise]);
  } catch (e) {
    throw new Error(e);
  }
  const fields = overview.items[0].fields as Fields;
  const posts = entries.items;

  const postsCount = entries.total;
  const pageCount = Math.ceil(postsCount / BLOG_ENTRIES_PER_PAGE);

  return {
    props: {
      fields,
      posts: optimizeContentfulImages(posts),
      postsCount,
      pageCount,
      preview: !!preview,
    },
  };
}

export default BlogIndex;
